<!-- eslint-disable max-len -->
<template>
  <!-- 付款與發票 -->
  <div class="cart_block mb50" id="cartInvoice">
    <h4 class="txt-bold">付款與發票</h4>
    <div class="payment_area">
      <!-- 付款方式 -->
      <div class="payment_item way">
        <h6 class="txt-bold">付款方式</h6>
        <div class="item_in">
          <div v-if="marketCode && isCompany === 1">
            <p class="is_company_info h6">月結：{{ payWay === 1 ? '當月' : '跨月' }}</p>
          </div>
          <div v-else class="radio_box">
            <span
              v-for="(data, i) in payments"
              :key="`payment_${i}`">
                <!-- {{itemHasRent}} -->
                <template v-if="data.text === '信用卡'">
                  <input
                    class="payment_radio"
                    type="radio"
                    name="payment_way"
                    value="crade"
                    :id="data.id"
                    @input="
                      $emit('selectPayment', data.id);
                    ">
                  <label :for="data.id">
                    <p>信用卡</p>
                    <img src="@/assets/img/shopping/pay_card.png" alt="">
                  </label>
                </template>
                <!--<template v-else-if="data.text === 'LINEPAY' && !checkItemHasWifi">
                  <input
                    class="payment_radio"
                    type="radio"
                    name="payment_way"
                    value="linepay"
                    :id="data.id"
                    @input="
                      $emit('selectPayment', data.id);
                    ">
                  <label :for="data.id">
                    <img src="@/assets/img/shopping/pay_line_new.svg" alt="">
                  </label>
                </template>-->
                <template v-else-if="data.text === '銀行匯款' && !checkItemHasWifi">
                  <input
                    class="payment_radio"
                    type="radio"
                    name="payment_way"
                    value="atm"
                    :id="data.id"
                    @input="
                      $emit('selectPayment', data.id);
                    ">
                  <label :for="data.id">
                    <p>銀行匯款</p>
                  </label>
                </template>
            </span>
          </div>
          <p class="payment_way_note txt-redorange">
            當您有預約WiFi分享器時，刷卡金額會包含租金及押金$1 元的預留授權金，<br>此押金不會實際請款及出現於信用卡帳單內，30天內取消此筆授權。
          </p>
          <p
            v-if="cartForm.payment_id === getAtmId && cartForm.payment_id !== null"
            class="payment_way_note">
              轉帳銀行: 國泰世華商業銀行-安和分行<br>
              銀行帳號：027-03-500349-1<br>
              戶名：特樂通股份有限公司
          </p>
          <div class="note" v-if="cartForm.payment_id === getAtmId && cartForm.payment_id !== null">
            <p class="txt-greenlake small">
              ※ 若您選擇銀行匯款方式付款，請於訂單備註處輸入您的匯款帳號後五碼，謝謝。
            </p>
          </div>
        </div>
        <!-- <div class="item_in" v-if="cartForm.payment_id === getCreditCardId">
          <div class="fill_box">
            <p class="mg14">信用卡號<span class="txt-redorange">*</span></p>
            <div class="fill_in">
              <div class="col50">
                <input type="number" v-model="creditForm.number">
              </div>
            </div>
          </div>
          <div class="fill_box">
            <p class="mg14">有效期限<span class="txt-redorange">*</span></p>
            <div class="fill_in">
              <div class="col50">
                <input type="text" v-model="creditForm.expiredDay" class="form-control" v-cardformat:formatCardExpiry>
              </div>
            </div>
          </div>
          <div class="fill_box">
            <p class="mg14">授權碼<span class="txt-redorange">*</span></p>
            <div class="fill_in">
              <div class="col50">
                <input type="text" v-model="creditForm.verifyCode" class="form-control" v-cardformat:formatCardCVC>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- 發票資訊 -->
      <div class="payment_item invoice">
        <h6 class="txt-bold">發票資訊</h6>
        <div class="item_in">
          <div class="radio_box">
            <p>發票<span class="txt-redorange">*</span></p>
            <div>
              <div class="r_box">
                <input
                  @input="$emit('invoiceType', 1)"
                  type="radio" name="invo" id="personal_invo" value="personal_invo" checked>
                <label for="personal_invo">
                  <span></span> <p>個人電子發票</p>
                </label>
                <input
                  @input="$emit('invoiceType', 2)"
                  type="radio" name="invo" id="conpany_invo" value="conpany_invo">
                <label for="conpany_invo">
                  <span></span> <p>公司戶電子發票</p>
                </label>
                <input
                  @input="$emit('invoiceType', 3)"
                  type="radio" name="invo" id="gift_invo" value="gift_invo">
                <label for="gift_invo">
                  <span></span> <p>捐贈</p>
                </label>
              </div>
              <div class="for_personal_invo" style="display: block" v-if="cartForm.invoice_type === 1">
                <select v-model="cartForm.invoice_style" v-if="cartForm.invoice_type === 1">
                  <option
                    v-for="(data, i) in invoice_style"
                    :value="data.value"
                    :key="`invoice_style_${i}`">
                      {{data.text}}
                  </option>
                </select>
                <input
                  type="text"
                  class="small"
                  v-if="cartForm.invoice_style === 2"
                  v-model="cartForm.carrier_code"
                  placeholder="請輸入手機載具">
                <input
                  type="text"
                  class="small"
                  v-if="cartForm.invoice_style === 3"
                  v-model="cartForm.carrier_code"
                  placeholder="請輸入自然人憑證">
              </div>
              <div class="for_conpany_invo" v-if="cartForm.invoice_type === 2">
                <input
                  type="text"
                  class="small company"
                  v-model="cartForm.company_no"
                  style="::"
                  placeholder="請輸入公司統一編號">
              </div>
              <div class="for_gift_invo" v-if="cartForm.invoice_type === 3">
                <select v-model="cartForm.donate_org_code">
                  <option :value="null" disabled>請選擇欲捐贈機構</option>
                  <option
                    v-for="(data, i) in donateOrgs"
                    :value="data.id"
                    :key="`donateOrgs_${i}`">
                      {{data.text}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="note">
            <p class="txt-greenlake small">
              ※ 國外上網卡開立專用之「國際電話卡收據」，可開立公司統編 <span class="note_note txt-white">i</span> <br>
              ※ 旅遊相關票券將提供代收轉付收據
            </p>
          </div>
        </div>
      </div>
      <!-- 訂單備註 -->
      <div class="payment_item ps">
        <h6 class="txt-bold">訂單備註</h6>
        <div class="item_in">
          <div class="text_box">
            <p>備註</p>
            <div>
              <textarea
                v-model="cartForm.content"
                class="small"
                name="" id=""
                cols="30"
                rows="3"
                placeholder="請輸入您的備註"
              ></textarea>
            </div>
          </div>
        </div>
        <input
          :value="acceptRefund"
          @input="$emit('acceptRefund', !acceptRefund)"
          type="checkbox"
          name="agree"
          id="agree1">
        <label for="agree1">
          <span></span>
          <small>我同意辦理退貨時，由特樂通股份有限公司代為處理電子發票及銷貨退回證明單，以加入退貨退款作業。(必選)</small>
        </label><br>
        <input
          :value="acceptTerms"
          @input="$emit('acceptTerms', !acceptTerms)"
          type="checkbox"
          name="agree"
          id="agree2">
        <label for="agree2">
          <span></span>
          <small>我同意 <span class="txt-lightblue">
            <router-link to="/Terms/Rent" target="_blank" rel="noopener">租賃條款</router-link>
            </span> 及 <span class="txt-lightblue">
            <router-link to="/Terms/Privacy" target="_blank" rel="noopener">隱私權聲明</router-link>
            </span> 。(必選)</small>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import requestApi from '@/lib/http/index';

export default {
  name: 'CartInvoice',
  props: ['cartForm', 'acceptTerms', 'acceptRefund'],
  data() {
    return {
      payments: [],
      donateOrgs: [],
      invoice_style: [
        {
          text: '紙本電子發票',
          value: 1,
        },
        {
          text: '手機載具',
          value: 2,
        },
        {
          text: '自然人憑證',
          value: 3,
        },
      ],
      marketCode: '', // 隱形賣場code
      isCompany: 0, // 企業帳戶 （0: 否, 1: 是）
      payWay: null, // 月結方式（1: 當月, 2: 跨月）
    };
  },
  methods: {

    getFilter() {
      // 取得付款方式
      const getPayments = new Promise((resolve, reject) => {
        const result = requestApi('req.getPayments', { is_frontend: 1 });
        result.then((res) => {
          if (res.status) {
            const arr = [];
            res.data.forEach((element) => {
              arr.push({
                text: element.name,
                id: element.id,
              });
            });
            resolve(arr);
          } else {
            reject(new Error('取得付款方式資料錯誤'));
          }
        });
      });

      // 取得捐贈單位
      const getDonateOrgs = new Promise((resolve, reject) => {
        const result = requestApi('req.getDonateOrgs');
        result.then((res) => {
          if (res.status) {
            const arr = [];
            res.data.forEach((element) => {
              arr.push({
                text: element.name,
                id: element.id,
              });
            });
            resolve(arr);
          } else {
            reject(new Error('取得捐贈單位資料錯誤'));
          }
        });
      });

      // 執行
      Promise.all([getPayments, getDonateOrgs]).then((values) => {
        const [payments, donateOrgs] = values;
        this.payments = payments;
        this.donateOrgs = donateOrgs;

        if (this.marketCode && this.isCompany === 1) {
          if (this.payWay === 1) {
            this.$emit('selectPayment', this.payments.find((payment) => payment.text === '當月結').id);
          } else if (this.payWay === 2) {
            this.$emit('selectPayment', this.payments.find((payment) => payment.text === '跨月結').id);
          }
        }
      }).catch((err) => {
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: err.message,
          confirmButtonText: '確定',
          thenFunc: () => {
            this.$router.back();
          },
        });
      });
    },
  },
  mounted() {
    this.getFilter();

    /** 判斷是否為隱形賣場 */
    this.marketCode = window.localStorage.getItem('marketCode') || '';
    this.isCompany = Number(this.$store.state?.user?.is_company) || '';
    this.payWay = Number(this.$store.state?.user?.pay_way) || '';
  },
  computed: {

    ...mapState([
      'orderDetail',
    ]),

    checkItemHasWifi() {
      let itemHasWifi = false;
      if (this.orderDetail && this.orderDetail.length > 0) {
        this.orderDetail.forEach((item) => {
          if (item.category === 'WIFI分享器') {
            itemHasWifi = true;
          }
        });
      }
      if (!itemHasWifi) itemHasWifi = this.$store.state.plusHasWifi;
      return itemHasWifi;
    },

    getCreditCardId() {
      let id = null;
      if (this.payments) {
        this.payments.forEach((item) => {
          if (item.text === '信用卡') {
            id = item.id;
          }
        });
      }
      return id;
    },
    getAtmId() {
      let id = null;
      if (this.payments) {
        this.payments.forEach((item) => {
          if (item.text === '銀行匯款') {
            id = item.id;
          }
        });
      }
      return id;
    },
  },
};
</script>
<style scoped>
::placeholder {
  color: #000000;
}
.is_company_info {
  padding: 2rem 0 1rem;
}
</style>
