<template>
  <div id="cartDetail">
    <CartShip
      @county="county"
      @district="district"
      @zipcode="zipcode"
      @selectType="selectType"
      @historyData="historyData"
      @update="methodUpdateCartForm"
      :historyAddress="historyAddress"
      :cartForm="cartForm"/>
    <CartInvoice
      :cartForm="cartForm"
      :acceptRefund="acceptRefund"
      :acceptTerms="acceptTerms"
      @selectPayment="selectPayment"
      @invoiceType="invoiceType"
      @acceptRefund="passAcceptRefund"
      @acceptTerms="passAcceptTerms"/>
    <CartMain />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import CartMain from '@/components/cart/CartMain.vue';
import CartShip from '@/components/cart/CartShip.vue';
import CartInvoice from '@/components/cart/CartInvoice.vue';
import requestApi from '@/lib/http/index';
import { isEqual } from '../../lib/lodash';

export default {
  name: 'CartDetail',
  components: {
    CartMain,
    CartShip,
    CartInvoice,
  },
  data() {
    return {
      memberAddress: {},
      historyAddress: [],
      cartForm: {
        flight_abroad: null,
        take_way_id: null,
        return_way_id: null,
        take_option_id: null,
        return_option_id: null,
        name: null,
        sex: '',
        email: null,
        country_code: null,
        mobile: null,
        tel: null,
        tel_1: null,
        payment_id: null,
        invoice_type: 1,
        invoice_style: 1,
        carrier_type: null,
        carrier_code: null,
        company_no: null,
        donate_org_code: null,
        content: null,
        cvsspot: null,
        cvsnum: null,
        family_name: null,
      },
      discountEvent: {
        value: null,
        result: false,
        message: null,
        data: null,
      },
      acceptRefund: false,
      acceptTerms: false,
      currentCartType: null,
      cartsHasRent: null,
    };
  },
  computed: {
    ...mapState([
      'order',
      'takeWay',
      'totalPriceAndCount',
      'user',
      'isLogin',
    ]),
  },
  methods: {
    ...mapMutations([
      'setTotalPriceAndCount',
      'setOrder',
      'setOrderDetail',
      'setAcceptRefund',
      'setAcceptTerms',
    ]),
    passAcceptTerms(value) {
      this.acceptTerms = value;
    },
    passAcceptRefund(value) {
      this.acceptRefund = value;
    },
    invoiceType(value) {
      this.cartForm = {
        ...this.cartForm,
        invoice_type: value,
      };
    },
    selectPayment(id) {
      this.cartForm = {
        ...this.cartForm,
        payment_id: id,
      };
    },
    selectType(v) {
      const type = parseInt(v, 10);
      console.log('type', type);
      if (type === 1) {
        const {
          county, district, address, zipcode,
        } = this.memberAddress;
        this.cartForm = {
          ...this.cartForm,
          county,
          district,
          address,
          zipcode,
        };
      } else if (type === 2) {
        this.resetAddress();
        this.getHistoryAddress();
      } else if (type === 0) {
        this.resetAddress();
        this.resetMemberInfo();
      } else {
        this.resetAddress();
      }
    },
    async getHistoryAddress() {
      // eslint-disable-next-line
      const member_id = this.user.id;
      const result = await requestApi('cart.getHistoryAddress', { member_id });
      if (result.status) {
        this.historyAddress = result.data;
      } else {
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: result.message,
          confirmButtonText: '確定',
        });
      }
    },
    resetAddress() {
      this.cartForm = {
        ...this.cartForm,
        county: '',
        district: '',
        zipcode: '',
        address: null,
      };
    },
    resetMemberInfo() {
      this.cartForm = {
        ...this.cartForm,
        name: '',
        sex: 'm',
        email: '',
        country_code: '',
        mobile: '',
        tel: '',
        tel_1: '',
      };
    },
    zipcode(v) {
      this.cartForm = {
        ...this.cartForm,
        zipcode: v,
      };
    },
    county(e) {
      this.cartForm = {
        ...this.cartForm,
        county: e.target.value,
        district: null,
        zipcode: null,
      };
    },
    district(e) {
      this.cartForm = {
        ...this.cartForm,
        district: e.target.value,
        zipcode: null,
      };
    },
    historyData(data) {
      // eslint-disable-next-line
      const { county, district, zipcode, address, ...others } = this.cartForm;
      this.cartForm = {
        county: data.county,
        district: data.district,
        zipcode: data.zipcode,
        address: data.address,
        ...others,
      };
    },
    putOrderData() {
      const order = JSON.parse(localStorage.getItem('order'));
      if (order) {
        /* eslint-disable camelcase */
        const {
          flight_abroad, take_option_id, return_option_id, take_way_id, return_way_id,
        } = order;
        this.cartForm = {
          ...this.cartForm,
          flight_abroad,
          take_option_id,
          return_option_id,
          take_way_id,
          return_way_id,
        };
      }
    },
    async getMemberData() {
      // eslint-disable-next-line
      const member_id = this.user.id;
      const result = await requestApi('cart.getMemberAddress', { member_id });
      if (result.status) {
        // eslint-disable-next-line
        const { county, district, zipcode, address, ...others } = result.data;
        let addressForm;
        if (this.takeWay === '宅配') {
          this.memberAddress = {
            county,
            district,
            zipcode,
            address,
          };
          addressForm = {
            county,
            district,
            zipcode,
            address,
          };
        } else {
          addressForm = {};
        }
        await Object.keys(others).forEach((key) => {
          if (result.data[key]) {
            this.cartForm[key] = result.data[key];
          }
        });
        this.cartForm = {
          ...this.cartForm,
          ...addressForm ?? {},
        };
      } else {
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: '無法取得會員聯絡地址',
          confirmButtonText: '確定',
        });
      }
    },
    methodUpdateCartForm(data = {}) {
      if (data) {
        this.cartForm = {
          ...this.cartForm,
          ...data,
        };
      }
    },
  },
  created() {
    if (this.isLogin) {
      this.putOrderData();
      this.getMemberData();
    }
  },
  watch: {
    takeWay() {
      this.getMemberData();
    },
    acceptRefund(val) {
      this.setAcceptRefund(val);
    },
    acceptTerms(val) {
      this.setAcceptTerms(val);
    },
    cartForm: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal) && newVal) {
          this.setOrder(newVal);
        }
      },
      deep: true,
    },

  },
  beforeDestroy() {
    this.setAcceptRefund(false);
    this.setAcceptTerms(false);
  },
};
</script>
